.status-menu{
    position: fixed;
    right: 0;
    top: calc(50% - 200px);
    display: flex;
    flex-flow: column;
    background: #fff;
    border-radius: 15px 0 0 15px;
    padding: 15px 0 15px 15px;
    gap:15px;
}
.status-menu__item{
    position: relative;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px 0 0 10px;
    outline: 3px solid var(--passiveStatus);
    border: 2px solid #fff;
    border-right: none;
    width: 75px;
    height: 80px;
    background:var(--passiveStatus);
    margin-bottom:15px;
}
.status-menu__item:after{
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    height: 100%;
    width: 2px;
    background: #94c5fb;
    bottom: -20px;
    z-index: -1;
}
.status-menu__item:before{
    content: '';
    position: absolute;
    left: 0;
    width: 0;
    height: 0;
    right: 0;
    margin: 0 auto;
    border: 5px solid transparent;
    border-top: 10px solid #94c5fb;
    bottom: -32px;
    z-index: 1;
}
.status-menu__item:last-child:after,
.status-menu__item:last-child::before{
    display: none;
}
.status-menu__item img{
    width: 30px;
    height: 30px;
    object-fit: cover;
}
.status-menu__item.active{
    background:var(--activeStatus);
    outline: 3px solid var(--activeStatus);
}
.status-menu__item p{
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    text-align: center;
    color: #000;
    max-width: 65px;
    margin-bottom: 5px;
}