.scan-wrapper{
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 80px;
    height: 100vh;
}
.scan-wrapper h1{
    font-weight: 400;
    font-size: 36px;
    text-transform: uppercase;
    text-align: center;
    color: #000;
}
.scanner{
    display: flex;
    width: 100%;
    height: 100%;
    max-height: 340px;
    max-width: 340px;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 60px auto;
}
.scanner img{
    position: absolute;
    width: 340px;
    height: 340px;
    object-fit: contain;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}
.scanner video{
    width:250px;
    height: 250px;
    object-fit: cover;
    border-radius: 15px;
}
.photo-wrapper{
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
.buttons-wrapper{
    display: flex;
    width: 100%;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    margin: 20px auto;
}
.buttons-wrapper button{
    margin: 20px;
}
.react-html5-camera-photo{
    width: 100%;
    height: 100%;
    max-width: 400px;
    max-height: 300px;
    border-radius: 15px;
}
.react-html5-camera-photo video{
    width: 100%;
    height: 100%;
    max-width: 400px;
    max-height: 300px;
    border-radius: 15px;
    margin: auto;
}
.react-html5-camera-photo #container-circles{
    display: none;
}
.react-html5-camera-photo>img,
.react-html5-camera-photo>video{
    width: 100%!important;
    height: 100%;
    max-width: 400px;
    max-height: 300px;
    border-radius: 15px;
}
.demo-image-preview,
.demo-image-preview > img{
    width: 100%!important;
    height: 100%;
    max-width: 400px;
    max-height: 300px;
    object-fit: cover;
    border-radius: 15px;
    margin: 0 auto;
}