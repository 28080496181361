.items-wrap{
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    max-width: 1024px;
    gap: 35px;
}
.items-wrap p{
    text-transform: uppercase;
}

.item-card{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 3px solid #c1cdd9;
    background: #fff;
    border-radius: 15px;
    width: 134px;
    height: 134px;
}
.item-card img{
    width: 100px;
    height: 100px;
    object-fit:contain;
}
.qr-link{
    font-family: "Roboto Flex", sans-serif;
    position: fixed;
    right: 0;
    top: 20px;
    display: flex;
    flex-flow: column;
    text-decoration: none;
}
.qr-link p{
    font-weight: 700;
    font-size: 9px;
    line-height: 144%;
    text-transform: uppercase;
    text-align: center;
    color: #3a3838;
    margin:0 5px 5px 0;
}
.qr-link svg{
    background: #fff;
    margin-left: 10px;
    padding-right: 2px;
    border-radius: 15px 0 0 15px;
}
@media screen and (max-width: 1124px) {
    .items-wrap{
        max-width: 820px;
        gap:18px;
    }
    .item-card{
        width: 120px;
        height: 120px;
    }
    .search{
        margin-bottom: 18px;
    }
    .status-menu{
        padding: 10px 0 10px 10px;
    }
}
@media screen and (max-width: 840px) {
    .items-wrap{
        max-width: 660px;
        gap:15px;
    }
}
@media screen and (max-width: 770px) {
    .items-wrap{
        max-width: 540px;
        gap:15px;
    }
    .item-card{
        width: 120px;
        height: 120px;
    }
    .search{
        max-width: 520px;
    }
}