/*header*/

.background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
    z-index: -1;
}
.inner-page{
    margin: auto;
    padding: 35px 0;
}
.inner-page .background-image{
    -webkit-filter: blur(5px);
    filter: blur(5px);
}

.header-info{
    display: flex;
    flex-flow: column;
    justify-content: start;
    align-items: center;
    padding-top: 150px;
    height: 100vh;
    margin: 0 auto;
}
.header-info img{
    object-fit: contain;
    max-width: 100%;
    height: 70px;
}
.header-info .logo{
    display: flex;
    align-items: center;
    background: linear-gradient(45deg, #3e759d0f, #7bc4f226);
    padding: 10px;
    border-radius: 15px;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    outline: 3px solid #ffffff91;
}
.header-info .logo p{
    font-size:50px;
    line-height: 140%;
    font-weight: 700;
    background: -webkit-linear-gradient(#1f407d 0% , #1f407d 50% , #183d8a 100%); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(#1f407d  0% ,#1f407d 50% ,#183d8a  100% ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-transform: uppercase;
    margin-left: 5px;
}
.header-info h1{
    position: relative;
    margin-bottom: 40px;
    color: #1f407d;
    text-transform: uppercase;
    font-weight: 500;
}
.header-info h1:before{
    content: '';
    position: absolute;
    display: block;
    height: 3px;
    width: 100%;
    background: linear-gradient(4deg, transparent, #1a356c8a, transparent);
    bottom: -5px;
}
.header-title {
    margin: 0;
    font-size: 1.8em;
}
