.top-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 30px 40px 30px;
}
.top-menu.absolute{
    position: absolute;
    top: 20px;
}

.top-menu .back-btn {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.top-menu .back-btn img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    margin-right: 5px;
}

.top-menu .back-btn p {
    font-weight: 400;
    font-size: 16px;
    line-height: 112%;
    text-transform: uppercase;
    color: #000;
}

.top-menu .top-menu__company {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    border-radius: 15px;
    overflow: hidden;
    background: #fff;
    outline: 3px solid var(--activeStatus);
    border: 2px solid #fff;
}
.top-menu .top-menu__company img{
    object-fit: contain;
    width: 50px;
    height: 50px;
    padding: 5px;
}

.contact-form {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    max-width: 1015px;
    gap: 15px;
}
.contact-form.main{
    flex-flow: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 500px;
    margin: 100px auto;
}
.contact-book{
    position: absolute;
    width: 60px;
    height: 60px;
    object-fit: contain;
    top: 10px;
    right: -80px;
    background: #bedbfa;
    outline: 3px solid var(--activeStatus);
    border: 2px solid #fff;
    border-radius: 15px;
    cursor: pointer;
    padding: 10px;
}
.rules{
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    height: 80px;
    padding: 15px;
    background: rgb(173 188 230 / 45%);
    border-radius: 15px;
}
.rules label{
    cursor: pointer;
    display: flex;
    align-items: center;
}
.rules label p{
    font-weight: 400;
    font-size: 13px;
    line-height: 138%;
    color: #242426;
    margin-left: 15px;
}
.rules label p a{
    text-transform: uppercase;
    text-decoration: underline;
    font-weight: 700;
}
.rules input {
    display: none;
}
.rules svg {
    overflow: visible;
}

.rules .path {
    fill: none;
    stroke: #000;
    stroke-width: 6;
    stroke-linecap: round;
    stroke-linejoin: round;
    transition: stroke-dasharray 0.5s ease, stroke-dashoffset 0.5s ease;
    stroke-dasharray: 241 9999999;
    stroke-dashoffset: 0;
}
.rules input:checked ~ svg{
    outline: 3px solid #000;
    padding: 5px;
    border-radius: 5px;
}
.rules input:checked ~ svg .path {
    stroke-dasharray: 70.5096664428711 9999999;
    stroke-dashoffset: -262.2723388671875;
}


.contact-form input {
    width: 100%;
    max-width: 500px;
    height: 80px;
    border-radius: 15px;
    background: #f0f4f9;
    border: 2px solid #8d9ba7;
    text-align: center;
    font-weight: 400;
    font-size: 24px;
    color: #6b7480;
    transition: all .3s linear;
}

.contact-form input:active,
.contact-form input:hover,
.contact-form input:focus {
    border-color: #5a8ac0;
}

.contact-form button {
    width: 100%;
    max-width: 300px;
    border: none;
    border-radius: 15px;
    background: #000;
    font-weight: 400;
    font-size: 24px;
    line-height: 75%;
    text-transform: uppercase;
    text-align: center;
    color: #fff;
    height: 80px;
    transition: all .3s linear;
    margin: 40px auto;
    cursor: pointer;
}

.contact-form button:active,
.contact-form button:hover,
.contact-form button:focus {
    opacity: .8;
}

.user-wrap {
    display: flex;
    flex-flow: row wrap;
    gap: 15px;
    max-width: 1015px;
    margin-top: 15px;
}

.user-wrap .user {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    width: 190px;
    height: 140px;
    border-radius: 15px;
    background: #f0f4f9;
}

.user .user__info {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 28px;
}

.user .user__info img {
    width: 50px;
    height: 50px;
    object-fit: cover;
}

.user .user__info p {
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    color: #000;
    max-width: 100px;
    margin: 0 5px;
}

.user .user__status {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 159px;
    height: 28px;
    font-weight: 400;
    font-size: 10px;
    background: #000;
    color: #fff;
    border-radius: 0 15px 0 0;
    padding: 0 15px;
    bottom: 0;
    left: 0;
}

.user .user__status.waiting {
    background: #37ff93;
    color: #000;
}
.finish-text-wrap{
    height: 100vh;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
}
.finish-text{
    width: 100%;
    max-width: 680px;
    border-radius: 15px;
    margin:0 auto 20px ;
    background: rgb(97 149 255 / 5%);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 -1px 2px rgb(216 216 216 / 50%), inset 0 -1px 2px rgb(7 7 7 / 20%);
    padding: 20px;
    overflow: hidden;
}
.finish-text h2{
    font-weight: 400;
    font-size: 36px;
    line-height: 140%;
    text-transform: uppercase;
    text-align: center;
    color: #242222;
    margin: 15px auto;
}
.scan-steps{
    display: flex;
    flex-flow: row;
    gap: 30px;
}
.last-scan-step{
    position: relative;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    background: #a0cef824;
    backdrop-filter: blur(15px);
    width: 100vw;
    height: 100vh;
    margin: auto;
    padding-bottom: 56.25%;
}
.scan-video{
    width: 100%;
    height: 100%;
    object-fit: contain  ;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.scan{
    position: absolute;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    background: #fff;
    outline: 3px solid #fff;
    border: 2px solid var(--activeStatus);
    width: 100px;
    height: 100px;
    border-radius: 50%;
    right: -40px;
    bottom: -40px;
    z-index: 2;
}
.scan:after{
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-left: 45px solid transparent;
    border-right: 45px solid transparent;
    border-bottom: 120px solid rgba(255, 255, 255, 0.47);
    left: -42px;
    transform: rotate(300deg);
    top: -45px;
    z-index: -1;
}

.scan-detail h3,
.last-scan-step h3{
    font-size: 14px;
    line-height: 100%;
    padding: 5px;
    text-align: center;
    position: absolute;
    top: -15px;
    width: 80%;
    background: #a0cef8;
    outline: 3px solid var(--activeStatus);
    border: 2px solid #fff;
    border-radius: 15px;
    text-transform: uppercase;
}
.scan-detail img{
    width: 100%;
    height: 100%;
    object-fit: none;
    max-width: 100%;
}
.last-scan-step img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    max-height: 180px;
}


.scan .qrcode{
    position: relative;
    width: 75px;
    height: 45px;
    background: url("images/shtrihcode01.png");
    -webkit-background-size: 150px;
    background-size: 150px;
}
.scan .qrcode:before{
    content:'';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: url("images/shtrihcode02.png");
    -webkit-background-size: 150px;
    background-size: 150px;
    -webkit-animation: animate 4s ease-in-out infinite;
    -o-animation: animate 4s ease-in-out infinite;
    animation: animate 4s ease-in-out infinite;
}
@keyframes animate {
    0%,100%{
        height: 10px;
    }
    50%{
        height: calc(100% - 0px);
    }
}
.scan .qrcode:after{
    content: '';
    position: absolute;
    inset: 5px;
    width: calc(100% - 10px);
    height: 2px;
    background: #0066ff;
    filter:drop-shadow(0 0 20px #0066ff) drop-shadow(0 0 60px #0066ff);
    -webkit-animation: animationLine 4s ease-in-out infinite;
    -o-animation: animationLine 4s ease-in-out infinite;
    animation: animationLine 4s ease-in-out infinite;
}
.close-icon{
    width: 60px;
    position: absolute;
    right: 65px;
    top: 135px;
    margin: 15px;
    background-color: #ffffff59;
    border-radius: 15px;
}

@keyframes animationLine {
    0%,100%{
        top: 10px;
    }
    50%{
        top: calc(100% - -5px);
    }
}
@media screen and (max-width: 1180px) {
    .contact-form{
        justify-content: center;
    }
    .contact-form input {
        max-width: 400px;
    }
    .rules{
        max-width: 400px;
        height: auto;
    }
    .user-wrap{
        justify-content: center;
    }
}
@media screen and (max-width: 860px) {
    .rules{
        max-width: 460px;
    }
    .contact-form input {
        max-width: 460px;
    }
}
.contact-form .input-error {
    border: 2px solid #fa0303;
}
.input-error {
    border: 2px solid #fa0303;
}
.card-rules{
    width: 95%;
    height: 75vh;
    margin: 0 auto;
    padding: 15px;
    background: rgb(173 188 230 / 45%);
    border-radius: 15px;
    color: black;
    overflow-y: scroll;
}
.rules-text{
    margin-right: 130px;
}
