/*//Reset CSS*/
/* Box sizing rules */
*,
*::before,
*::after {
    box-sizing: border-box;
}

/* Prevent font size inflation */
html {
    -moz-text-size-adjust: none;
    -webkit-text-size-adjust: none;
    text-size-adjust: none;
}

/* Remove default margin in favour of better control in authored CSS */
body, h1, h2, h3, h4, p,
figure, blockquote, dl, dd {
    margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
    list-style: none;
}

/* Set core body defaults */
body {
    min-height: 100vh;
    line-height: 1.5;
}

/* Set shorter line heights on headings and interactive elements */
h1, h2, h3, h4,
button, input, label {
    line-height: 1.1;
}

/* Balance text wrapping on headings */
h1, h2,
h3, h4 {
    text-wrap: balance;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
    text-decoration-skip-ink: auto;
    color: currentColor;
}
a{
    text-decoration: none;
}
/* Make images easier to work with */
img,
picture {
    max-width: 100%;
    display: block;
}

/* Inherit fonts for inputs and buttons */
input, button,
textarea, select {
    font: inherit;
    outline: none;
}

/* Make sure textarea without a rows attribute are not tiny */
textarea:not([rows]) {
    min-height: 10em;
}

/* Anything that has been anchored to should have extra scroll margin */
:target {
    scroll-margin-block: 5ex;
}
/*//Reset CSS AND*/


@import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,100..1000&display=swap');

body {
    margin: 0;
    font-family: "Roboto Flex", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    font-variation-settings: "slnt" 0,
    "wdth" 100,
    "GRAD" 0,
    "XOPQ" 96,
    "XTRA" 468,
    "YOPQ" 79,
    "YTAS" 750,
    "YTDE" -203,
    "YTFI" 738,
    "YTLC" 514,
    "YTUC" 712;
    color: var(--baseColor);
    background: var(--baseBackground);
    overflow: hidden;
}

:root {
    --lime-green: hsl(163, 72%, 41%);
    --bright-red: hsl(356, 69%, 56%);
    --facebook: #168ef4;
    --twitter: hsl(203, 89%, 53%);
    --instagram-1: hsl(37, 97%, 70%);
    --instagram-2: hsl(329, 70%, 58%);
    --youTube: hsl(348, 97%, 39%);
    --gray: #9b9ea7;
    --cardGray: #f0f3fa;
    --lightGray: #f8f9fe;
    --cardTitle: #f0f3fa;
    --baseBackground: white;
    --baseColor: #1d2029;
    --switchSpinner: white;
    --switch: #5f616d;
    --activeStatus:#bedbfa;
    --passiveStatus:#c1cdd9;
}

.wrapper {
    padding: 0 35px;
    height: 100vh;
}

p {
    margin: 0;
    padding: 0;
}

.grid {
    display: grid;
    grid-row-gap: 30px;
}

.search {
    font-family: "Roboto Flex", sans-serif;
    border: 2px solid #8d9ba7;
    border-radius: 15px;
    height: 80px;
    width: 100%;
    max-width: 979px;
    outline: none;
    font-weight: 400;
    font-size: 24px;
    text-align: center;
    color: #6b7480;
    padding: 0 60px;
    background: #f0f4f9 url("../../public/images/search-icon.png") 30px 50% no-repeat;
    margin-bottom: 35px;
    transition: all .3s linear;
}

.search:active,
.search:hover,
.search:focus {
    border-color: #5a8ac0;
}
.main-btn{
    width: 100%;
    max-width: 300px;
    border: none;
    border-radius: 15px;
    background: #000;
    font-weight: 400;
    font-size: 24px;
    line-height: 75%;
    text-transform: uppercase;
    text-align: center;
    color: #fff;
    height: 80px;
    transition: all .3s linear;
    margin: 40px auto;
    cursor: pointer;
}
@media screen and (min-width: 480px) {
    .grid {
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 30px;
    }

    .wrapper {
        max-width: 1440px;
        margin: auto;
    }
}

@media screen and (min-width: 768px) {
    .grid {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media screen and (min-width: 1024px) {
    .grid {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    .header-total {
        border: none;
    }

    .header-grid {
        display: grid;
        grid-template-columns: 1fr 200px;
    }
}

@media screen and (max-width: 1124px) {
    .search {
        max-width: 810px;
    }
}
@media screen and (max-width: 840px) {
    .search {
        max-width: 660px;
    }
}
@media screen and (max-width: 770px) {
    .search {
        max-width: 500px;
        margin-bottom: 15px;
    }
}
.is-dark-mode {
    --cardGray: #252b43;
    --baseBackground: #1d2029;
    --baseColor: white;
    --gray: #8088ad;
    --lightGray: #1d2029;
    --cardTitle: #8088ad;
    --switchSpinner: #282943;
    --switch: linear-gradient(to right, #4796d2 0%, #37cebf 100%);
}

.is-light-mode {
    --gray: #9b9ea7;
    --cardGray: #f0f3fa;
    --lightGray: #f8f9fe;
    --cardTitle: #575a66;
    --baseBackground: white;
    --baseColor: #1d2029;
    --switchSpinner: white;
    --switch: #5f616d;
}

@media (prefers-color-scheme: dark) {
    :root {
        --cardGray: #252b43;
        --baseBackground: #1d2029;
        --baseColor: white;
        --gray: #8088ad;
        --lightGray: #1d2029;
        --cardTitle: #8088ad;
        --switchSpinner: #282943;
        --switch: linear-gradient(to right, #4796d2 0%, #37cebf 100%);
    }
}

h2 {
    color: var(--gray);
}
